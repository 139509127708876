<template>
	<div
		class="column justify-center align-center text-white text-weight-bold"
		style="max-width: 97vw; margin-top: 30px; padding: 10px"
	>
		<h1
			class="app-title-font app-title-shadow"
			style="font-size: 5rem; color: #ffa000; line-height: 0.5"
		>
			قرآني
		</h1>
		<h5
			class="app-subtitle-font app-subtitle-shadow text-h5"
			style="font-size: 1.5rem; color: #ffa000"
		>
			أحيوا أمرنا
		</h5>

		<div style="margin: 20px auto 10px auto">
			<a href="https://app.qurani.ca" class="button-link text-font"
				>تشغيل التطبيق</a
			>
		</div>

		<div class="badges-container">
			<a
				href="https://play.google.com/store/apps/details?id=ca.qurani.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
				target="_blank"
				><img
					alt="احصل عليه من Google Play"
					src="https://play.google.com/intl/en_us/badges/static/images/badges/ar_badge_web_generic.png"
					style="width: 190px"
			/></a>
			<a
				href="https://apps.apple.com/us/app/qurani/id6664058739"
				target="_blank"
				><img
					alt="احصل عليه من App Store"
					src="@/assets/images/app-store-badge.svg"
					style="width: 163px"
			/></a>
		</div>

		<div>
			<a
				href="http://www.waafisoft.ca"
				target="_blank"
				style="
					color: yellow;
					text-decoration: none;
					margin: 30px auto;
					text-shadow: 2px 2px 2px rgba(0, 0, 0, 1);
				"
				>Developed by Waafisoft</a
			>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			appName: process.env.VUE_APP_NAME,
		}),
	}
</script>

<style>
	html {
		width: 100%;
		height: 100%;
		margin: 0;
		/* background-color: rgb(1, 107, 1); */
	}

	body {
		width: 100%;
		height: 100%;
		margin: 0;
		background-image: url("~@/assets/images/bg.webp");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	#app {
		height: 100%;
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		align-items: center;
		/* color: white; */
	}

	.badges-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		margin: 0 auto 20px auto;
	}

	.button-link {
		display: inline-block;
		padding: 10px 20px;
		background-color: #006666;
		color: white;
		text-decoration: none;
		border-radius: 8px;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
		transition: background-color 0.3s ease;
		width: 125px;
	}

	.button-link:hover {
		background-color: #008080;
	}
</style>
